<template>
  <el-form
    inline
    ref="searchForm"
    :model="filterData"
    @submit.native.prevent
    @keyup.enter.native="search()"
  >
    <el-form-item
      v-show="isProfitGoal"
      label="年度："
      prop="annual"
      style="margin-bottom: 0"
    >
      <el-date-picker
        style="width: 150px"
        v-model="filterData.annual"
        type="year"
        placeholder="选择年"
        format="yyyy"
        value-format="yyyy"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item
      label="展示方式："
      prop="category"
      style="margin-bottom: 0"
      v-show="isType"
    >
      <el-select v-model="filterData.category" style="width: 80px">
        <el-option
          v-for="item in typeList"
          :key="item.label"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item
      v-show="!isProfitGoal"
      prop="dataRange"
      style="margin-bottom: 0"
    >
      <el-date-picker
        style="width: 250px"
        v-model="filterData.dataRange"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        :clearable="false"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item
      prop="creator_name"
      style="margin-bottom: 0"
    >
      <el-input
        type="text"
        placeholder="请输入达人昵称"
        v-model="filterData.creator_name"
      ></el-input>
    </el-form-item>
    <el-form-item style="margin-bottom: 0">
      <el-button type="primary" @click="search()">查询</el-button>
      <el-button plain native-type="button" @click="reset()">重置</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import moment from "moment";
export default {
  props: ["isType", "isProfitGoal", "notDefaultDate"],
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      typeList: [
        { value: "1", label: "按日" },
        { value: "2", label: "按周" },
        { value: "3", label: "按月" },
      ],
      filterData: {
        category: "1",
        dataRange: [],
        creator_name: "",
        annual: "",
      },
    };
  },
  created() {
    this.getRecentMonth();
  },
  methods: {
    getRecentMonth() {
      if (this.notDefaultDate) {
        this.filterData.dataRange = [];
      } else {
        this.filterData.dataRange = [
          moment().subtract(29, "d").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ];
      }
    },
    search() {
      var pd = {};
      if (this.isProfitGoal) {
        pd = {
          creator_name: this.filterData.creator_name,
          annual: this.filterData.annual,
        };
      } else {
        pd = {
          start_date:
            Array.isArray(this.filterData.dataRange) &&
            this.filterData.dataRange.length === 2
              ? this.filterData.dataRange[0]
              : "",
          end_date:
            Array.isArray(this.filterData.dataRange) &&
            this.filterData.dataRange.length === 2
              ? this.filterData.dataRange[1]
              : "",
          creator_name: this.filterData.creator_name,
        };
      }
      if (this.isType) {
        pd.category = this.filterData.category;
      }
      this.$emit("search", pd);
    },
    reset() {
      this.$refs.searchForm.resetFields();
      this.getRecentMonth();
      this.search();
    },
  },
};
</script>
